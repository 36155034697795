import React from 'react'

import { Layout } from '../components/layout'
import { SEO } from '../components/seo'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Nenalezeno" />

    <h1>Stránka nenalezena</h1>

    <p>Bohužel tato stránka nebyla nalezena.</p>
  </Layout>
)

export default NotFoundPage
